import styled, { css, keyframes, createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";
import * as colors from "../../styles/abstracts/_variables.styles";
import * as breakpoints from "../../styles/abstracts/_breakpoints";

// Breakpoints
const {
  xxSmallDevices,
  xxSmallDevicesLandscape,
  extraSmallDevices,
  extraSmallDevicesLandscape,
  mediumDevices,
  mediumDevicesLandscape,
  largeDevices,
  extraLargeDevices,
  xxLargeDevices,
} = breakpoints;

export const FooterDiv = styled(motion.div)`
  margin: 0rem 0rem;
  padding: 0rem 0rem;
  background: ${colors.bg_color};
  height: auto;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const TopFooterDiv = styled(motion.div)`
  margin: 4rem 2rem 0rem 2rem;
  padding: 2rem 0rem;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  border-top: 0.25rem solid ${colors.warmPalette.primary};
  border-bottom: 0.25rem solid ${colors.warmPalette.primary};
  height: auto;
  display: grid;
  grid-template-rows: 40vh 40vh;
  grid-template-columns: 100%;
  gap: 3vw;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    margin: 4rem 4rem 0rem 4rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    grid-template-rows: 40vh 40vh;
    grid-template-columns: 92vw;
    gap: 3vw;
  }
  @media ${extraLargeDevices} {
    margin: 4rem 4rem 0rem 4rem;
    grid-template-rows: 40vh;
    grid-template-columns: 45vw 45vw;
    gap: 3vw;
  }
  @media ${xxLargeDevices} {
  }
`;

export const BottomFooterDiv = styled(motion.div)`
  height: auto;
  margin: 0rem 4rem 2rem 4rem;
  padding: 2rem 0rem;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  ${"" /* border-bottom: 0.25rem solid ${colors.warm_palatte_primary}; */}
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const FootItemDiv = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 2rem 0rem;
  padding-right: 10rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const Img = styled(motion.img)`
  margin: ${(props) => (props.margin ? props.margin : "0rem 0rem")};
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  overflow: hidden;
  cursor: pointer;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const Button = styled(motion.button)`
  background-color: inherit;
  border: none;
  color: black;
  padding: 0rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
export const YTSDiv = styled(motion.div)`
  margin: 0rem auto 0rem auto;
  text-align: left;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    font-size: 1.25rem;
  }
  @media ${mediumDevicesLandscape} {
    font-size: 1.25rem;
  }
  @media ${largeDevices} {
    font-size: 1.25rem;
  }
  @media ${extraLargeDevices} {
    font-size: 1.25rem;
  }
  @media ${xxLargeDevices} {
    font-size: 1.25rem;
  }
`;

export const FooterContainer = styled.div`
  height: 30vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.5fr;
  margin: 2rem 6rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const SocialContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  height: 40%;
  gap: 0.1rem;
  justify-items: center;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    justify-content: flex-start;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    gap: 0.5rem;
  }
  @media ${extraLargeDevices} {
    width: 100%;
    height: 40%;
    gap: 0.5rem;
  }
  @media ${xxLargeDevices} {
    width: 100%;
    height: 40%;
    gap: 0.5rem;
  }
`;

export const PrivacyContainer = styled.div`
  justify-self: start;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: 1fr;
  width: auto;
  height: 80%;
  margin: 0rem 0rem;
  gap: 0.75rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
export const BookNowContainer = styled(motion.div)`
  justify-self: end;
  align-self: end;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr;
  width: 80%;
  height: 20%;
  margin: 1rem 0rem;
  gap: 0.75rem;
  cursor: pointer;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const MotionP = styled(motion.p)`
  height: auto;
  font-size: 2rem;
  width: 100%;
  cursor: pointer;
  text-align: right;
  margin: 0rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${"" /* align-items: center; */}
  justify-content: space-around;
  ${"" /* height: 20%; */}
  gap: 0rem;
  margin: 0rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    align-items: start;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  ${"" /* height: 6rem; */}
  width: 100%;
  gap: 1rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    align-items: center;
    ${"" /* height: auto; */}
    width: 55vw;
    gap: 2rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    align-items: center;
    ${"" /* height: 800%; */}
    ${"" /* width: 30vw; */}
		gap: 2rem;
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const LogoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    width: 5rem;
  }
  @media ${extraLargeDevices} {
    width: 6rem;
  }
  @media ${xxLargeDevices} {
    width: 6rem;
  }
`;

export const ImgContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    width: 100%;
    height: 100%;
  }
  @media ${extraLargeDevices} {
    width: 100%;
    height: 100%;
  }
  @media ${xxLargeDevices} {
  }
`;

export const TextSpan = styled(motion.span)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  height: 20%;
  ${"" /* position: relative; */}
  width: 15rem;
  @media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    width: 20rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    width: 80%;
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;

export const TextSpanOne = styled(motion.span)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  height: 20%;
  ${"" /* position: relative; */}
  ${"" /* width: 15rem; */}
	@media ${xxSmallDevices} {
  }
  @media ${xxSmallDevicesLandscape} {
  }
  @media ${extraSmallDevices} {
  }
  @media ${extraSmallDevicesLandscape} {
  }
  @media ${mediumDevices} {
    width: 20rem;
  }
  @media ${mediumDevicesLandscape} {
  }
  @media ${largeDevices} {
    width: 40%;
  }
  @media ${extraLargeDevices} {
  }
  @media ${xxLargeDevices} {
  }
`;
